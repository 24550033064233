export const camelToPascal = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
/**
 * Helper function to convert an object to a query string.
 * ie. { colours: ["blue", "green"], sort: "alpha" } -> "?colours=blue,green&sort=alpha"
 * @param obj 
 * @returns Formatted query string or empty string 
 */
export const getParamStringFromObject = (
  obj: Record<string, string | string[] | number | boolean | undefined>
): string => {
  const queryParams: string[] = [];

  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        queryParams.push(`${key}=${value.join(",")}`);
      } else if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      ) {
        queryParams.push(`${key}=${encodeURIComponent(value.toString())}`);
      }
    }
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
  return queryString;
};
