import type { ProductFilters, ListResponse, PartialProduct } from "../types";
import { getParamStringFromObject } from "~/utils/helpers";

export const listProducts = async <T extends ProductFilters["fields"]>(
  filters: ProductFilters & { fields?: T }
): Promise<ListResponse<PartialProduct<T>>> => {
  const params = getParamStringFromObject(filters);
  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/products${params}`
  );

  if (!response.ok) {
    // TODO
    throw new Error();
  }

  // throw new Error("error thrown in listProducts()");
  const data: ListResponse<PartialProduct<T>> = await response.json();
  return data;
};
